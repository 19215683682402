import React from 'react';

export default function SvgMoney({ dark, className }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            className={className ? className : ''}
        >
            <path
                fill={dark ? "#FFFFFF" : "#06262D"}
                d="M8 1C4.138 1 1 4.138 1 8s3.138 7 7 7 7-3.138 7-7-3.138-7-7-7zm0 .609c3.533 0 6.391 2.858 6.391 6.391S11.533 14.391 8 14.391 1.609 11.533 1.609 8 4.467 1.609 8 1.609zm.426 2.647c-.822 0-2.465.366-2.952 2.861h-.517l-.183.457h.67c-.031.091-.031.335-.031.396v.212h-.456l-.183.457h.67c.365 2.74 2.069 3.074 2.921 3.074.7 0 1.279-.213 1.857-.67v-.79c-.852.882-1.43.882-1.796.882-.67 0-1.978-.274-2.282-2.496h2.89l.183-.457H6.082V8.03c0-.152 0-.274.031-.456H9.4l.182-.457H6.174C6.57 5.11 7.787 4.865 8.426 4.865c.64 0 1.187.244 1.644.792l.273-.609c-.426-.517-1.065-.792-1.917-.792z"
            />
        </svg>
    );
}