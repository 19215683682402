import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import { fetchDealStatus } from "../../fetch"
import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"

import GatsbyButtonComponent from "@solublestudio/dozen-design-system/src/components/Gatsby/GatsbyButtonComponent"
import { default as DDSOportunidadMainInfoComponent } from "@solublestudio/dozen-design-system/src/components/OportunidadMainInfoComponent"

import { getItem } from "../../utils"

import {
    PRECOMMITMENTS_KEY,
    PRECOMMITMENT,
    CURRENT_INVESTORS_KEY,
} from "../../hooks/ProjectsState"

export default function OportunidadMainInfoComponent({
    investedText,
    remainingDaysText,
    percentageCompleted,
    images = [],
    investButtons = [],
    webId,
    slug,
    status,
    currentInvestorsRound,
    labels,
    ...props
}) {
    const translation = useTranslate()

    const [textInvestedNow, setTextInvestedNow] = useState(investedText)
    const [textMissingDaysNow, setTextMissingDaysNow] =
        useState(remainingDaysText)
    const [percentageCompletedNow, setPercentageCompletedNow] =
        useState(percentageCompleted)

    const [projectLabels, setProjectLabels] = useState(labels ?? [])

    useEffect(() => {
        if (!webId || typeof window === "undefined") {
            return
        }

        fetchDealStatus(webId).then(({ status, data }) => {
            if (status === 200 && data) {
                setPercentageCompletedNow(data.percentageCompleted)
                setTextInvestedNow(data.investedText)
                setTextMissingDaysNow(data.remainingDaysText)
                setProjectLabels(data?.labels)
            }
        })
    }, [webId])

    useEffect(() => {
        if (typeof window !== "undefined") {
            let previous = getItem(PRECOMMITMENTS_KEY) ?? []

            const addedToStorage = previous.indexOf(slug) !== -1

            if (status !== PRECOMMITMENT && addedToStorage) {
                previous = previous.filter((thisSlug) => thisSlug !== slug)
            }

            const data =
                status !== PRECOMMITMENT ? previous : [...previous, slug]

            window.localStorage.setItem(
                PRECOMMITMENTS_KEY,
                JSON.stringify([...new Set(data)])
            )
        }
    }, [status, slug])

    useEffect(() => {
        if (typeof window !== "undefined") {
            let previous = getItem(CURRENT_INVESTORS_KEY) ?? []
            previous.push(slug)

            window.localStorage.setItem(
                CURRENT_INVESTORS_KEY,
                JSON.stringify([...new Set(previous)])
            )
        }
    }, [currentInvestorsRound, slug])

    return (
        <DDSOportunidadMainInfoComponent
            {...props}
            labels={projectLabels}
            status={status}
            breadcrumbTag={Link}
            breadcrumbScheme="gatsby"
            investButtons={<GatsbyButtonComponent buttons={investButtons} />}
            images={[]}
            mainImage={props?.mainImage?.childImageSharp?.resize?.src}
            percentageCompleted={percentageCompletedNow}
            investedText={textInvestedNow}
            remainingDaysText={textMissingDaysNow}
            productTypeText={translation["opportunity.detail.producttype"].text}
            precommitmentLabelText={
                translation["opportunity.card.precommitment_label"].text
            }
            currentInvestorsRoundText={
                translation["opportunity.card.current_investors_round_label"]
                    .text
            }
            currentInvestorsRound={currentInvestorsRound}
            taxIncentiveText={translation["opportunity.card.taxincentive"].text}
        />
    )
}
