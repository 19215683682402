import React, { useState, useEffect, useMemo } from "react"
import {
    Container,
    Row,
    Col,
    Heading,
    className,
    SectionComponent,
    CommentsComponent,
} from "@solublestudio/dozen-design-system"
import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"
import { default as DDSOportunidadDetailComponent } from "@solublestudio/dozen-design-system/src/components/OportunidadDetailComponent"
import { default as DSDownloadLink } from "@solublestudio/dozen-design-system/src/components/DownloadLink"
import GatsbyButtonComponent from "@solublestudio/dozen-design-system/src/components/Gatsby/GatsbyButtonComponent"
import DocumentList from "@solublestudio/dozen-design-system/src/components/DocumentListComponent"

import ContentComponent from "../ContentComponent"

import { useAuthState } from "../../hooks/AuthState"

import { fetchDealDocuments, fetchDealTabs } from "../../fetch"

import style from "./style.module.scss"

export default function OportunidadDetailComponent({
    details = [],
    protectionButtons = [],
    loggedInProtectionButtons = [],
    protectionTitleNode = null,
    addPb = false,
    projectId = null,
    stickyMenuProps = {},
}) {
    const { isLogged, token } = useAuthState()
    const [privateTabs, setPrivateTabs] = useState(null)
    const [documents, setDocuments] = useState([])
    const [isProtected, setIsProtected] = useState(true)
    const [activeTab, setActiveTab] = useState(null)

    const _ = useTranslate()

    const tabs = useMemo(
        () => [
            ...(privateTabs ? privateTabs : details),
            {
                name: _ ? _["opportunity.detail.documents"].text : "",
                slug: _
                    ? _["opportunity.detail.documents"]?.text?.toLowerCase()
                    : "",
                requiresAuth: true,
                content:
                    token && documents
                        ? [
                              {
                                  component: (
                                      <DocumentList
                                          title={null}
                                          items={documents}
                                          DownloadDocument={(props) => (
                                              <DSDownloadLink
                                                  {...props}
                                                  token={token}
                                              />
                                          )}
                                          downloadLabel={
                                              _[
                                                  "opportunity.detail.documents.download"
                                              ].text
                                          }
                                      />
                                  ),
                              },
                          ]
                        : null,
            },
            {
                name: _ ? _["opportunity.detail.comments"].text : "",
                slug: _
                    ? _["opportunity.detail.comments"]?.text?.toLowerCase()
                    : "",
                requiresAuth: true,
                content: token
                    ? [
                          {
                              component: (
                                  <CommentsComponent
                                      URL={`${process.env.GATSBY_DOZEN_API}/api`}
                                      token={token}
                                      pageId={projectId}
                                  />
                              ),
                          },
                      ]
                    : null,
            },
        ],
        [_, privateTabs, details, documents, token, projectId, token]
    )

    useEffect(() => {
        if (privateTabs) {
            setIsProtected(false)
            return
        }

        const active = tabs[activeTab] || tabs[0]
        setIsProtected(active && active.requiresAuth)
    }, [privateTabs, tabs, activeTab])

    useEffect(() => {
        if (projectId && token) {
            fetchDealTabs(projectId, token).then((res) => {
                if (res?.data) {
                    setPrivateTabs(res.data)
                    setIsProtected(false)
                }
            })
            fetchDealDocuments(projectId, token).then((res) => {
                setDocuments(res?.data)
            })
        }
    }, [projectId, token, setPrivateTabs, setIsProtected])

    return details?.length || privateTabs?.length ? (
        <DDSOportunidadDetailComponent
            details={tabs}
            stickyMenuProps={{
                uppercase: true,
                ...stickyMenuProps,
            }}
            onChange={setActiveTab}
            contentPt={activeTab === tabs?.length - 1 ? {} : { xs: 8, lg: 10 }}
            TheContentComponent={ContentComponent}
            afterSectionContent={(content) => {
                return isProtected ? (
                    <div
                        {...className(
                            `${style.protected} ${
                                content && content.length
                                    ? style["with-content"]
                                    : ""
                            }`
                        )}
                    >
                        <SectionComponent
                            light={true}
                            className={`mt-12 pt-8 pb-8 pt-lg-11 pb-lg-11 ${
                                addPb ? "mb-12" : ""
                            } mb-lg-25`}
                        >
                            <Container>
                                <Row>
                                    <Col
                                        offset={{ lg: 2 }}
                                        col={{ lg: 8 }}
                                        className="text-lg-center"
                                    >
                                        <Heading
                                            mb={{ xs: 6 }}
                                            tag="h4"
                                            className="h4"
                                        >
                                            {
                                                protectionTitleNode
                                                    .childMarkdownRemark
                                                    .htmlWithoutWrapper
                                            }
                                        </Heading>
                                        <GatsbyButtonComponent
                                            buttons={
                                                isLogged
                                                    ? loggedInProtectionButtons
                                                    : protectionButtons
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </SectionComponent>
                    </div>
                ) : (
                    <div
                        {...className(
                            activeTab === tabs?.length - 1
                                ? "pt-4 pt-lg-8"
                                : "pt-10 pt-lg-25"
                        )}
                    />
                )
            }}
        />
    ) : (
        <div {...className("pt-10 pt-lg-25")} />
    )
}
