import React from 'react';
import {
    Heading,
    Text,
    className,
} from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../ButtonComponent';

import style from './style.module.scss';

function DocumentRow({ title, buttonProps, DownloadDocument }) {
    return (
        <li className={style.item}>
            <Text tag="p" className={`h6 ${style.text}`}>
                {title}
            </Text>
            <div {...className('pl-1')}>
                {DownloadDocument ? (
                    <DownloadDocument button={buttonProps} />
                ) : (
                    <ButtonComponent button={buttonProps} />
                )}
            </div>
        </li>
    );
}

export default function DocumentList({
    title = 'Documents',
    items = [],
    dueDiligenceTitle = 'Due Diligence',
    dueDiligenceCtaLabel = 'Ver detalles',
    onClickDueDiligenceModal = null,
    downloadLabel,
    downloadEvent,
    DownloadDocument,
}) {
    return (
        <>
            {title ? (
                <Heading className={`d3 mb-5 font-weight-light ${style.title}`}>
                    {title}
                </Heading>
            ) : null}
            <ul {...className(`list-unstyled ${style.list}`)}>
                {onClickDueDiligenceModal ? (
                    <DocumentRow
                        title={dueDiligenceTitle}
                        buttonProps={{
                            Tag: 'button',
                            block: false,
                            detached: false,
                            label: dueDiligenceCtaLabel,
                            onClick: onClickDueDiligenceModal,
                            className: style.btn,
                        }}
                    />
                ) : null}
                {items.map((item, i) => {
                    return (
                        <DocumentRow
                            key={i}
                            title={item.name}
                            DownloadDocument={DownloadDocument}
                            buttonProps={{
                                block: false,
                                detached: false,
                                href: item.downloadUrl,
                                download: true,
                                label: downloadLabel,
                                extraAttributes: {
                                    download: item.fileName,
                                    ...(downloadEvent && {
                                        'data-event': downloadEvent,
                                    }),
                                },
                                className: style.btn,
                            }}
                        />
                    );
                })}
            </ul>
        </>
    );
}
