import React from 'react';

import { className, Slider } from '@solublestudio/react-bootstrap-components';

import style from './style.module.scss';

const sliderSettings = {
    controls: false,
    loop: true,
    nav: true,
    navContainer: '#slider-thumbnails',
    navAsThumbnails: true,
    mouseDrag: true,
    arrowKeys: true,
};

export default function ImageSliderComponent({
    images,
    wrapperClassName = '',
}) {
    const finalImages = images ? images.filter((i) => !!i) : [];
    
    return (
        <div {...className(wrapperClassName)}>
            {finalImages.length > 1 ? (
                <>
                    <Slider settings={sliderSettings}>
                        {finalImages.map((image, i) => (
                            <div key={i}>
                                <img
                                    alt=""
                                    {...className('img-fluid')}
                                    src={image}
                                />
                            </div>
                        ))}
                    </Slider>
                    <ul
                        {...className(
                            `mt-1 list-unstyled ${style['thumbs-wrapper']} d-flex justify-content-start mb-lg-0`,
                        )}
                        id="slider-thumbnails">
                        {finalImages.map((image, i) => (
                            <li {...className(style.thumb)} key={i}>
                                <div 
                                    style={{
                                        backgroundImage: `url(${image})`
                                    }}
                                    className={style['thumb-image']}
                                />
                            </li>
                        ))}
                    </ul>
                </>
            ) : finalImages.length ? (
                <img alt="" {...className('img-fluid')} src={finalImages[0]} />
            ) : null}
        </div>
    );
}
