import React from "react"
import { graphql } from "gatsby"

import { Layout } from "@solublestudio/gatsby-theme-soluble-source"

import OportunidadMainInfoComponent from "../components/OportunidadMainInfoComponent"
import OportunidadDetailComponent from "../components/OportunidadDetailComponent"
import DealSection from "../components/DealSection"
import NewsletterSection from "../components/NewsletterSection"

import { useProjects } from "../hooks/ProjectsState"

export default function Deal({ data, pageContext }) {
    const {
        investButtons = [],
        protectionButtons = [],
        loggedInProtectionButtons = [],
        protectionTitleNode = "",
        otherOportunitiesTitleNode,
        otherOportunitiesButton,
    } = data.detail.edges.length ? data.detail.edges[0].node : {}

    const [mergedProjects] = useProjects(data.dozenProject.otherProjects ?? [])

    return (
        <Layout
            navbarFixed={true}
            navbarTransparent={true}
            customSeo={pageContext.seo}
            isDark={
                data.dozenProject.status === "restricted" ||
                data.dozenProject.currentInvestorsRound
            }
        >
            <OportunidadMainInfoComponent
                {...data.dozenProject}
                parentPage={data.opportunitiesPage}
                investButtons={investButtons.map((b) => ({
                    ...b,
                    extraAttributes: {
                        "data-event": "interesc1",
                    },
                    externalLink: b.externalLink
                        ? b.externalLink.replace(
                              "{slug}",
                              data.dozenProject.slug
                          )
                        : b.externalLink,
                }))}
            />
            <OportunidadDetailComponent
                projectId={data.dozenProject.webId}
                details={data.dozenProject.tabs}
                protectionButtons={protectionButtons.map((b) => ({
                    ...b,
                    externalLink: b.externalLink
                        ? b.externalLink.replace(
                              "{slug}",
                              data.dozenProject.slug
                          )
                        : b.externalLink,
                }))}
                loggedInProtectionButtons={loggedInProtectionButtons.map(
                    (b) => ({
                        ...b,
                        externalLink: b.externalLink
                            ? b.externalLink.replace(
                                  "{slug}",
                                  data.dozenProject.slug
                              )
                            : b.externalLink,
                    })
                )}
                protectionTitleNode={protectionTitleNode}
                addPb={mergedProjects && mergedProjects.length}
            />
            {mergedProjects && mergedProjects.length ? (
                <DealSection
                    titleNode={otherOportunitiesTitleNode}
                    projects={mergedProjects}
                    button={otherOportunitiesButton}
                    pt="pt-0"
                    altBg={true}
                />
            ) : null}
            <NewsletterSection
                isLast={true}
                removePt={true}
                alternativeBackground={mergedProjects && mergedProjects.length}
            />
        </Layout>
    )
}

export const query = graphql`
    query Deal($id: String, $language: String) {
        opportunitiesPage: datoCmsPage(isOpportunitiesPage: { eq: true }) {
            slug
            title
        }
        dozenProject(id: { eq: $id }) {
            images {
                childImageSharp {
                    resize(width: 888, height: 981, cropFocus: CENTER) {
                        src
                    }
                }
            }
            mainImage {
                childImageSharp {
                    resize(width: 888, height: 981, cropFocus: CENTER) {
                        src
                    }
                }
            }
            status
            cashTargetDisplay
            percentageCompleted
            investedText
            remainingDaysText
            name
            slug
            cardType
            description
            taxIncentive
            webId
            isConvertibleNote
            cashDisplay
            textCashInvested
            productType
            currentInvestorsRound
            labels {
                label
                value
            }
            tabs {
                name: title
                slug
                requiresAuth
                content {
                    h1
                    h2
                    h3
                    h4
                    h5
                    img {
                        alt
                        file {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(width: 1280, quality: 100)
                            }
                        }
                        src
                    }
                    ul
                    ol
                    p
                    html
                }
            }
            otherProjects {
                ...Project
            }
        }
        detail: allDatoCmsDealPage(filter: { locale: { eq: $language } }) {
            edges {
                node {
                    protectionTitleNode {
                        ...TextHtml
                    }
                    protectionButtons {
                        ...Button
                    }
                    loggedInProtectionButtons {
                        ...Button
                    }
                    investButtons {
                        ...Button
                    }
                    otherOportunitiesTitleNode {
                        ...TextHtml
                    }
                    otherOportunitiesButton {
                        ...Button
                    }
                }
            }
        }
        allDozenProject(filter: { status: { in: ["open", "restricted"] } }) {
            edges {
                node {
                    webId
                    slug
                    status
                }
            }
        }
    }
`
