import React, { useState, useEffect } from 'react';
import {
    Text,
    Row,
    Col,
    Container,
    Breadcrumb,
    className,
} from '@solublestudio/react-bootstrap-components';

import OportunidadProgressComponent from '../OportunidadProgressComponent';

import SectionComponent from '../SectionComponent';
import HeadingBold from '../HeadingBold';
import ValueComponent from '../ValueComponent';
import ImageSliderComponent from '../ImageSliderComponent';
import SvgMoney from '../SvgMoney';
import Label from '../Label';

import style from './style.module.scss';

export default function OportunidadMainInfoComponent({
    name,
    description,
    longDescription,
    cashTargetDisplay,
    percentageCompleted,
    investedText,
    remainingDaysText,
    status,
    productType,
    images = [],
    mainImage,
    parentPage = null,
    investButtons = null,
    productTypeText,
    precommitmentLabelText,
    // Breadcrumb
    breadcrumbTag = 'a',
    breadcrumbScheme = null,
    cardType,
    cashDisplay,
    textCashInvested,
    currentInvestorsRoundText = null,
    currentInvestorsRound = false,
    labels = [],
    taxIncentive,
    taxIncentiveText,
}) {
    const [sliderImages, setSliderImages] = useState(null);

    const isDark = currentInvestorsRound || status === 'restricted';
    const tertiaryLabel =
        status === 'restricted' && precommitmentLabelText
            ? precommitmentLabelText
            : currentInvestorsRound && currentInvestorsRoundText
            ? currentInvestorsRoundText
            : null;

    useEffect(() => {
        setSliderImages(null);
    }, [images, mainImage]);

    useEffect(() => {
        if (sliderImages === null && (images?.length || mainImage)) {
            setSliderImages(
                [images.indexOf(mainImage) === -1 ? mainImage : null]
                    .concat(images)
                    .filter((i) => !!i),
            );
        }
    }, [images, mainImage, sliderImages]);

    return (
        <SectionComponent
            className={['pt-4 pt-lg-7', 'pb-10 pb-lg-20']
                .filter(Boolean)
                .join(' ')}
            dark={isDark ? true : false}
            light={isDark ? false : true}>
            <Container>
                {parentPage ? (
                    <Row className="d-none d-lg-block">
                        <Col pb={{ xs: 4 }}>
                            <Breadcrumb
                                wrapperClassName={style.breadcrumb}
                                linkClassName={style['breadcrumb-link']}
                                liClassName={
                                    isDark ? style.breadcrumbLiDark : ''
                                }
                                links={[
                                    {
                                        Tag: breadcrumbTag,
                                        scheme: breadcrumbScheme,
                                        href: `/${parentPage.slug}/`,
                                        title: parentPage.title,
                                    },
                                    {
                                        title: name,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                ) : null}
                <Row pb={{ xs: 6, lg: 0 }}>
                    <Col
                        col={{ lg: 5 }}
                        className={
                            !sliderImages?.length && tertiaryLabel
                                ? style['image-wrapper-with-label']
                                : ''
                        }>
                        {sliderImages ? (
                            <ImageSliderComponent images={sliderImages} />
                        ) : null}
                        {tertiaryLabel ? (
                            <Label tertiary className={style.label}>
                                {tertiaryLabel}
                            </Label>
                        ) : null}
                        {taxIncentive && taxIncentiveText ? (
                            <Label className={style.label2}>
                                {taxIncentiveText}
                            </Label>
                        ) : null}
                    </Col>
                    <Col
                        offset={{ lg: 1 }}
                        col={{ lg: 6 }}
                        className="d-lg-flex flex-lg-column justify-content-lg-between">
                        <div>
                            <HeadingBold tag="h1" mb={{ xs: 3, lg: 2 }}>
                                {name}
                            </HeadingBold>
                            {productTypeText || productType ? (
                                <div
                                    {...className(
                                        `mb-5 mb-lg-2 d-flex align-items-center justify-content-start ${style['product-type']}`,
                                    )}>
                                    <SvgMoney dark={isDark} />
                                    <Text tag="p" className="caption">
                                        {[productTypeText, productType]
                                            .filter((i) => !!i)
                                            .join(' ')}
                                    </Text>
                                </div>
                            ) : null}
                            {longDescription || description ? (
                                <Text
                                    tag="p"
                                    className="p"
                                    mb={{ xs: 7, lg: 3 }}>
                                    {longDescription
                                        ? longDescription
                                        : description}
                                </Text>
                            ) : null}

                            <OportunidadProgressComponent
                                cashTargetFormatted={cashTargetDisplay}
                                percentageCompleted={percentageCompleted}
                                textInvested={investedText}
                                textMissingDays={remainingDaysText}
                                dark={isDark}
                                cashDisplay={cashDisplay}
                                textCashInvested={textCashInvested}
                                variant={cardType}
                            />

                            <Row
                                mb={{
                                    xs: investButtons ? 6 : 0,
                                    lg: investButtons ? 5 : 0,
                                }}>
                                {labels.map(({ label, value }, i) => (
                                    <Col col={{ xs: 6 }} key={i}>
                                        <ValueComponent
                                            title={label}
                                            value={value}
                                            dark={isDark}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        {investButtons ? <div>{investButtons}</div> : null}
                    </Col>
                </Row>
            </Container>
        </SectionComponent>
    );
}
