import React from "react"

import { Container, SectionComponent } from "@solublestudio/dozen-design-system"

import NewsletterCard from "../NewsletterCard"

import style from "./style.module.scss"

export default function NewsletterSection({
    isLast,
    removePt,
    alternativeBackground,
    previousSectionType,
}) {
    return (
        <SectionComponent
            alt={alternativeBackground}
            className={`${
                removePt ||
                (previousSectionType &&
                    previousSectionType.indexOf("Resources") !== -1)
                    ? ""
                    : "pt-lg-25"
            } ${isLast ? style.lastSection : ""}`}
        >
            <Container>
                <NewsletterCard />
            </Container>
        </SectionComponent>
    )
}
