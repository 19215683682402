import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
    ContentComponent as DDSContentComponent,
    className,
} from "@solublestudio/dozen-design-system"

export default function ContentComponent({ content = [], ...props }) {
    return (
        <DDSContentComponent
            {...props}
            content={
                content
                    ? content.map((c, i) => {
                          if (!c.img) {
                              return c
                          }

                          if (c.img.file) {
                              if (c.img.file.childImageSharp) {
                                  c.img = (
                                      <GatsbyImage
                                          {...className(
                                              "mt-4 mb-4 mt-lg-6 mb-lg-6"
                                          )}
                                          key={i}
                                          image={getImage(
                                              c.img.file.childImageSharp
                                          )}
                                          alt={c.img.alt}
                                      />
                                  )
                              } else if (c.img.file.publicURL) {
                                  c.img = c.img.file.publicURL
                              }
                          } else if (c.img.src) {
                              c.img = c.img.src
                          }

                          return c
                      })
                    : []
            }
        />
    )
}
